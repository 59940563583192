define("shared/components/cluster-driver/driver-gke/component", ["exports", "shared/mixins/cluster-driver", "shared/utils/util", "ui/models/cluster", "shared/components/cluster-driver/driver-gke/template", "semver"], function (_exports, _clusterDriver, _util, _cluster, _template, _semver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MINIMUM_VERSION = '>= 1.25.0';

  var _default = Ember.Component.extend(_clusterDriver.default, {
    google: Ember.inject.service(),
    intl: Ember.inject.service(),
    serviceVersions: Ember.inject.service('version-choices'),
    layout: _template.default,
    configField: 'gkeConfig',
    step: 1,
    errors: null,
    otherErrors: null,
    clusterErrors: null,
    save: false,
    clusterAdvanced: false,
    maintenanceWindowTimes: null,
    locationType: null,
    monitoringServiceChoices: null,
    loggingServiceChoices: null,
    sharedSubnets: null,
    clusterLocationSaved: false,
    clusterLocationSaving: false,
    showLogMonServiceWarning: false,
    clusterReleaseChannel: null,
    originalSecret: null,
    defalutNodePoolConfig: _cluster.DEFAULT_GKE_NODE_POOL_CONFIG,
    defaultGkeConfig: _cluster.DEFAULT_GKE_CONFIG,
    allErrors: Ember.computed.union('errors', 'otherErrors', 'clusterErrors'),
    isNew: Ember.computed.equal('mode', 'new'),
    editing: Ember.computed.equal('mode', 'edit'),
    clusterState: Ember.computed.alias('model.originalCluster.state'),

    init() {
      var _config2;

      this._super(...arguments);

      Ember.setProperties(this, {
        errors: [],
        otherErrors: [],
        clusterErrors: [],
        maintenanceWindowTimes: this.google.maintenanceWindows,
        locationType: this.google.defaultZoneType,
        monitoringServiceChoices: [{
          label: this.intl.t('generic.none'),
          value: 'none'
        }, {
          label: this.intl.t('clusterNew.googlegke.monitoringService.default'),
          value: 'monitoring.googleapis.com/kubernetes'
        }],
        loggingServiceChoices: [{
          label: this.intl.t('generic.none'),
          value: 'none'
        }, {
          label: this.intl.t('clusterNew.googlegke.loggingService.default'),
          value: 'logging.googleapis.com/kubernetes'
        }]
      });
      let config = Ember.get(this, 'cluster.gkeConfig');

      if (!config) {
        config = this.globalStore.createRecord(this.defaultConfig());
        Ember.set(this, 'cluster.gkeConfig', config);
      } else {
        var _config, _config$privateCluste;

        if (this.editing && this.importedClusterIsPending || this.clusterIsPending && (_config = config) !== null && _config !== void 0 && (_config$privateCluste = _config.privateClusterConfig) !== null && _config$privateCluste !== void 0 && _config$privateCluste.enablePrivateEndpoint) {
          Ember.set(this, 'step', 5);
        } else {
          this.syncUpstreamConfig();
          const initalTags = { ...(config.tags || {})
          };
          Ember.set(this, 'initalTags', initalTags);
        }
      }

      Ember.setProperties(this, {
        locationType: Ember.get(this, 'config.zone') ? this.google.defaultZoneType : this.google.defaultRegionType,
        regionChoices: this.google.regions.map(region => ({
          name: region
        }))
      });
      let cur = (_config2 = config) === null || _config2 === void 0 ? void 0 : _config2.googleCredentialSecret;

      if (cur && !cur.startsWith('cattle-global-data:')) {
        cur = `cattle-global-data:${cur}`;
      }

      Ember.set(this, 'originalSecret', cur);
    },

    actions: {
      finishAndSelectCloudCredential(cred) {
        if (cred) {
          Ember.run.next(this, () => {
            var _cred$googlecredentia;

            Ember.set(this, 'config.googleCredentialSecret', Ember.get(cred, 'id'));

            if (cred !== null && cred !== void 0 && (_cred$googlecredentia = cred.googlecredentialConfig) !== null && _cred$googlecredentia !== void 0 && _cred$googlecredentia.projectId) {
              Ember.set(this, 'config.projectID', cred.googlecredentialConfig.projectId);
              this.send('loadZones');
            }
          });
        }
      },

      addNodePool() {
        const config = Ember.get(this, `primaryResource.gkeConfig`);
        const kubernetesVersion = Ember.get(config, 'kubernetesVersion');
        let nodePools = (Ember.get(config, 'nodePools') || []).slice();
        const npConfig = JSON.parse(JSON.stringify(this.defalutNodePoolConfig));

        if (!Ember.isArray(nodePools)) {
          nodePools = [];
        }

        const nodePool = this.globalStore.createRecord(npConfig);
        Ember.setProperties(nodePool, {
          version: kubernetesVersion,
          isNew: true
        });
        nodePools.pushObject(nodePool);
        Ember.set(this, 'config.nodePools', nodePools);
      },

      removeNodePool(nodePool) {
        let {
          config: {
            nodePools = []
          }
        } = this;

        if (!Ember.isEmpty(nodePools)) {
          nodePools.removeObject(nodePool);
        }

        Ember.set(this, 'config.nodePools', nodePools);
      },

      loadZones(cb = () => {}) {
        Ember.set(this, 'errors', []);
        return Ember.RSVP.all([this.google.fetchZones(this.cluster, this.saved)]).then(resp => {
          const [zones] = resp;
          Ember.setProperties(this, {
            step: 2,
            zones
          });

          if (this.editing) {
            Ember.set(this, 'clusterLocationSaving', true);
            this.send('checkServiceAccount', () => {
              Ember.setProperties(this, {
                clusterLocationSaving: false,
                clusterLocationSaved: true
              });
            });
          }

          cb(true);
        }).catch(err => {
          this.send('errorHandler', err);
          cb(false);
        });
      },

      checkServiceAccount(cb = () => {}) {
        Ember.set(this, 'errors', []);
        const config = Ember.get(this, `cluster.${this.configField}`);
        const promises = [this.google.fetchVersions(this.cluster, this.saved), this.google.fetchMachineTypes(this.cluster, this.saved), this.google.fetchNetworks(this.cluster, this.saved), this.google.fetchSubnetworks(this.cluster, Ember.get(this, 'locationType'), this.saved), this.google.fetchSharedSubnets(this.cluster, this.saved), this.google.fetchServiceAccounts(this.cluster, this.saved)];

        if (this.editing) {
          promises.push(this.google.fetchClusters(this.cluster, this.saved));
        }

        return Ember.RSVP.all(promises).then(resp => {
          const [versions, machineTypes, networks, subNetworks, sharedSubnets, servicesAccounts, allClusters = []] = resp;
          Ember.setProperties(this, {
            step: 3,
            subNetworks,
            machineTypes,
            networks,
            servicesAccounts,
            sharedSubnets,
            versions
          });

          if ((allClusters || []).length > 0) {
            var _myCluster$releaseCha;

            const myCluster = allClusters.findBy('name', config === null || config === void 0 ? void 0 : config.clusterName);
            const releaseChannel = myCluster === null || myCluster === void 0 ? void 0 : (_myCluster$releaseCha = myCluster.releaseChannel) === null || _myCluster$releaseCha === void 0 ? void 0 : _myCluster$releaseCha.channel;

            if (!Ember.isEmpty(releaseChannel)) {
              Ember.set(this, 'clusterReleaseChannel', releaseChannel);
            }
          } // const filter = servicesAccounts.filter((o) => o.displayName === 'Compute Engine default service account')


          if (Ember.get(this, 'mode') === 'new') {
            // set(this, 'config.serviceAccount', filter?.firstObject && filter.firstObject.uniqueId)
            const defaultNet = networks.findBy('name', 'default');

            if (!Ember.isEmpty(defaultNet)) {
              Ember.set(this, 'config.network', defaultNet === null || defaultNet === void 0 ? void 0 : defaultNet.name);
            } else {
              Ember.set(this, 'config.network', (networks === null || networks === void 0 ? void 0 : networks.firstObject) && networks.firstObject.name);
            }
          }

          if (Ember.isEmpty(config.kubernetesVersion)) {
            Ember.set(this, 'config.kubernetesVersion', versions === null || versions === void 0 ? void 0 : versions.defaultClusterVersion);
          }

          cb(true);
        }).catch(err => {
          this.send('errorHandler', err);
          cb(false);
        });
      },

      addMSAN() {
        var _this$config, _this$config$masterAu;

        const cidrBlocks = (((_this$config = this.config) === null || _this$config === void 0 ? void 0 : (_this$config$masterAu = _this$config.masterAuthorizedNetworks) === null || _this$config$masterAu === void 0 ? void 0 : _this$config$masterAu.cidrBlocks) ?? []).slice();
        cidrBlocks.pushObject(this.globalStore.createRecord({
          cidrBlock: '',
          displayName: '',
          type: 'cidrblock'
        }));
        Ember.set(this, 'config.masterAuthorizedNetworks.cidrBlocks', cidrBlocks);
      },

      removeMSAN(MSAN) {
        var _this$config2, _this$config2$masterA;

        const cidrBlocks = ((_this$config2 = this.config) === null || _this$config2 === void 0 ? void 0 : (_this$config2$masterA = _this$config2.masterAuthorizedNetworks) === null || _this$config2$masterA === void 0 ? void 0 : _this$config2$masterA.cidrBlocks.slice()) ?? [];
        cidrBlocks.removeObject(MSAN);
        Ember.set(this, 'config.masterAuthorizedNetworks.cidrBlocks', cidrBlocks);
      }

    },
    networkPolicyEnabledChanged: Ember.observer('config.networkPolicyEnabled', function () {
      if (Ember.get(this, 'isNew') && Ember.get(this, 'config.networkPolicyEnabled')) {
        Ember.set(this, 'config.clusterAddons.networkPolicyConfig', true);
      }
    }),
    networkPolicyChanged: Ember.observer('cluster.enableNetworkPolicy', function () {
      const {
        cluster
      } = this;

      if (cluster !== null && cluster !== void 0 && cluster.enableNetworkPolicy) {
        Ember.setProperties(this, {
          'config.networkPolicyEnabled': true,
          'config.clusterAddons.networkPolicyConfig': true
        });
      }
    }),
    loggingServiceChanged: Ember.observer('config.loggingService', 'editing', function () {
      const {
        config,
        editing
      } = this;
      const loggingService = (config === null || config === void 0 ? void 0 : config.loggingService) || null;
      const monitoringService = (config === null || config === void 0 ? void 0 : config.monitoringService) || null;

      if (!editing) {
        return;
      }

      if (loggingService) {
        if (loggingService === 'none' && monitoringService !== 'none' || !monitoringService) {
          Ember.set(this, 'showLogMonServiceWarning', true);
        } else if (loggingService === 'logging.googleapis.com/kubernetes' && monitoringService === 'none') {
          Ember.set(this, 'showLogMonServiceWarning', true);
        } else {
          Ember.set(this, 'showLogMonServiceWarning', false);
        }
      }
    }),
    monitoringServiceChanged: Ember.observer('config.monitoringService', 'editing', function () {
      const {
        config,
        editing
      } = this;
      const loggingService = (config === null || config === void 0 ? void 0 : config.loggingService) || null;
      const monitoringService = (config === null || config === void 0 ? void 0 : config.monitoringService) || null;

      if (!editing) {
        return;
      }

      if (monitoringService) {
        if (monitoringService === 'none' && loggingService !== 'none' || !loggingService) {
          Ember.set(this, 'showLogMonServiceWarning', true);
        } else if (monitoringService === 'monitoring.googleapis.com/kubernetes' && loggingService === 'none') {
          Ember.set(this, 'showLogMonServiceWarning', true);
        } else {
          Ember.set(this, 'showLogMonServiceWarning', false);
        }
      }
    }),
    clusterLocationChanged: Ember.observer('locationType', function () {
      const {
        locationType
      } = this;

      if (locationType === 'regional') {
        Ember.setProperties(this, {
          'config.zone': null,
          'config.locations': ['us-central1-c'],
          'config.region': 'us-central1'
        });
      } else {
        Ember.setProperties(this, {
          'config.region': null,
          'config.zone': 'us-central1-c',
          'config.locations': []
        });
      }

      this.send('loadZones');
    }),
    clusterSecondaryRangeNameChanged: Ember.observer('config.ipAllocationPolicy.clusterSecondaryRangeName', 'secondaryIpRangeContent.[]', function () {
      if (this.isDestroyed || this.isDestroying || this.saving) {
        return;
      }

      const clusterSecondaryRangeName = Ember.get(this, 'config.ipAllocationPolicy.clusterSecondaryRangeName');
      const secondaryIpRangeContent = Ember.get(this, 'secondaryIpRangeContent') || [];
      const rangeMatch = secondaryIpRangeContent.findBy('value', clusterSecondaryRangeName);

      if (Ember.isEmpty(rangeMatch)) {
        if (!Ember.isEmpty(Ember.get(this, 'config.ipAllocationPolicy.clusterIpv4CidrBlock'))) {
          Ember.set(this, 'config.ipAllocationPolicy.clusterIpv4CidrBlock', null);
        }
      } else {
        Ember.set(this, 'config.ipAllocationPolicy.clusterIpv4CidrBlock', rangeMatch.ipCidrRange);
      }
    }),
    enablePrivateNodes: Ember.observer('config.privateClusterConfig.enablePrivateNodes', 'config.ipAllocationPolicy.useIpAliases', function () {
      var _config$privateCluste2;

      if (this.isDestroyed || this.isDestroying || this.saving) {
        return;
      }

      const {
        config
      } = this;

      if (config !== null && config !== void 0 && (_config$privateCluste2 = config.privateClusterConfig) !== null && _config$privateCluste2 !== void 0 && _config$privateCluste2.enablePrivateNodes) {
        Ember.setProperties(config, {
          'ipAllocationPolicy.useIpAliases': true,
          'masterAuthorizedNetworks.enabled': true
        });
      }
    }),
    networkChange: Ember.observer('config.network', 'subNetworkContent.[]', 'config.ipAllocationPolicy.useIpAliases', function () {
      if (this.isDestroyed || this.isDestroying || this.saving) {
        return;
      }

      const subNetworkContent = Ember.get(this, 'subNetworkContent') || [];

      if (subNetworkContent.length >= 1) {
        const firstNonNullSubnetMatch = subNetworkContent.find(sn => !Ember.isEmpty(sn.value));

        if (!Ember.isEmpty(firstNonNullSubnetMatch === null || firstNonNullSubnetMatch === void 0 ? void 0 : firstNonNullSubnetMatch.value)) {
          Ember.setProperties(this, {
            'config.subnetwork': firstNonNullSubnetMatch.value,
            'config.ipAllocationPolicy.createSubnetwork': false
          });
        } else {
          var _this$config3, _this$config3$ipAlloc;

          if ((_this$config3 = this.config) !== null && _this$config3 !== void 0 && (_this$config3$ipAlloc = _this$config3.ipAllocationPolicy) !== null && _this$config3$ipAlloc !== void 0 && _this$config3$ipAlloc.useIpAliases) {
            Ember.setProperties(this, {
              'config.subnetwork': '',
              'config.ipAllocationPolicy.createSubnetwork': true
            });
          } else {
            Ember.set(this, 'config.ipAllocationPolicy.createSubnetwork', false);
          }
        }

        Ember.setProperties(this, {
          'config.ipAllocationPolicy.subnetworkName': null,
          'config.ipAllocationPolicy.nodeIpv4CidrBlock': null
        });
      } else {
        var _this$config4, _this$config4$ipAlloc;

        Ember.setProperties(this, {
          'config.subnetwork': '',
          'config.ipAllocationPolicy.clusterSecondaryRangeName': null,
          'config.ipAllocationPolicy.servicesSecondaryRangeName': null
        });

        if ((_this$config4 = this.config) !== null && _this$config4 !== void 0 && (_this$config4$ipAlloc = _this$config4.ipAllocationPolicy) !== null && _this$config4$ipAlloc !== void 0 && _this$config4$ipAlloc.useIpAliases) {
          Ember.set(this, 'config.ipAllocationPolicy.createSubnetwork', true);
        } else {
          Ember.set(this, 'config.ipAllocationPolicy.createSubnetwork', false);
        }
      }
    }),
    servicesSecondaryRangeNameChanged: Ember.observer('config.ipAllocationPolicy.servicesSecondaryRangeName', 'secondaryIpRangeContent.[]', function () {
      if (this.isDestroyed || this.isDestroying || this.saving) {
        return;
      }

      const servicesSecondaryRangeName = Ember.get(this, 'config.ipAllocationPolicy.servicesSecondaryRangeName');
      const secondaryIpRangeContent = Ember.get(this, 'secondaryIpRangeContent') || [];
      const rangeMatch = secondaryIpRangeContent.findBy('value', servicesSecondaryRangeName);

      if (Ember.isEmpty(rangeMatch)) {
        if (!Ember.isEmpty(Ember.get(this, 'config.ipAllocationPolicy.servicesIpv4CidrBlock'))) {
          Ember.set(this, 'config.ipAllocationPolicy.servicesIpv4CidrBlock', null);
        }
      } else {
        Ember.set(this, 'config.ipAllocationPolicy.servicesIpv4CidrBlock', rangeMatch.ipCidrRange);
      }
    }),
    secondaryIpRangeContentChange: Ember.observer('secondaryIpRangeContent.[]', 'config.ipAllocationPolicy.useIpAliases', 'config.{network,subnetwork}', function () {
      if (this.isDestroyed || this.isDestroying || this.saving) {
        return;
      }

      const secondaryIpRangeContent = Ember.get(this, 'secondaryIpRangeContent') || [];

      if (secondaryIpRangeContent.length === 0) {
        Ember.setProperties(this, {
          'config.ipAllocationPolicy.clusterSecondaryRangeName': null,
          'config.ipAllocationPolicy.servicesSecondaryRangeName': null
        });
      }
    }),
    subnetworkChange: Ember.observer('config.subnetwork', 'config.ipAllocationPolicy.useIpAliases', function () {
      if (this.isDestroyed || this.isDestroying || this.saving) {
        return;
      }

      const {
        config: {
          subnetwork
        }
      } = this;

      if (Ember.isEmpty(subnetwork)) {
        var _this$config5, _this$config5$ipAlloc;

        Ember.setProperties(this, {
          'config.ipAllocationPolicy.clusterSecondaryRangeName': null,
          'config.ipAllocationPolicy.servicesSecondaryRangeName': null
        });

        if ((_this$config5 = this.config) !== null && _this$config5 !== void 0 && (_this$config5$ipAlloc = _this$config5.ipAllocationPolicy) !== null && _this$config5$ipAlloc !== void 0 && _this$config5$ipAlloc.useIpAliases) {
          Ember.set(this, 'config.ipAllocationPolicy.createSubnetwork', true);
        } else {
          Ember.set(this, 'config.ipAllocationPolicy.createSubnetwork', false);
        }
      } else {
        Ember.setProperties(this, {
          'config.ipAllocationPolicy.createSubnetwork': false,
          'config.ipAllocationPolicy.subnetworkName': null,
          'config.ipAllocationPolicy.nodeIpv4CidrBlock': null
        });
      }
    }),
    useIpAliasesChanged: Ember.observer('config.ipAllocationPolicy.useIpAliases', function () {
      if (this.isDestroyed || this.isDestroying || this.saving) {
        return;
      }

      const useIpAliases = Ember.get(this, 'config.ipAllocationPolicy.useIpAliases');

      if (useIpAliases) {
        if (!Ember.isEmpty(this.config.subnetwork)) {
          Ember.set(this, 'config.ipPolicyCreateSubnetwork', false);
        }
      } else {
        Ember.setProperties(this, {
          'config.ipAllocationPolicy.clusterSecondaryRangeName': null,
          'config.ipAllocationPolicy.servicesSecondaryRangeName': null
        });
      }
    }),
    postSaveChanged: Ember.observer('isPostSave', function () {
      const {
        isNew,
        isPostSave,
        config: {
          privateClusterConfig: {
            enablePrivateNodes
          }
        },
        importedClusterIsPending
      } = this;

      if ((enablePrivateNodes || importedClusterIsPending) && isPostSave) {
        if (isNew) {
          Ember.set(this, 'step', 5);
        } else {
          this.close();
        }
      } else {
        this.close();
      }
    }),
    showPolicyConfigWarning: Ember.computed('config.clusterAddons.networkPolicyConfig', 'editing', 'model.originalCluster.gkeStatus.upstreamSpec', 'upstreamSpec.clusterAddons.networkPolicyConfig', function () {
      const upstreamSpec = Ember.get(this, 'model.originalCluster.gkeStatus.upstreamSpec');

      if (this.editing && !Ember.isEmpty(upstreamSpec)) {
        const ogNetworkPolicyConfig = Ember.get(this, 'upstreamSpec.clusterAddons.networkPolicyConfig') ?? false;
        const currentNetworkPolicyConfig = Ember.get(this, 'config.clusterAddons.networkPolicyConfig') ?? false; // if user is turning off show warning

        if (ogNetworkPolicyConfig && !currentNetworkPolicyConfig) {
          return true;
        }
      }

      return false;
    }),
    showPolicyEnabledWarning: Ember.computed('config.networkPolicyEnabled', 'editing', 'model.originalCluster.gkeStatus.upstreamSpec.networkPolicyEnabled', function () {
      const upstreamSpec = Ember.get(this, 'model.originalCluster.gkeStatus.upstreamSpec');

      if (this.editing && !Ember.isEmpty(upstreamSpec)) {
        const ogNetworkPolicyEnabled = Ember.get(this, 'model.originalCluster.gkeStatus.upstreamSpec.networkPolicyEnabled') ?? false;
        const currentNetworkPolicyEnabled = Ember.get(this, 'config.networkPolicyEnabled') ?? false; // if user is turning off show warning

        if (ogNetworkPolicyEnabled && !currentNetworkPolicyEnabled) {
          return true;
        }
      }

      return false;
    }),
    shouldDisableNetworkPolicyEnabled: Ember.computed('config.clusterAddons.networkPolicyConfig', 'config.networkPolicyEnabled', 'editing', 'isNewOrEditable', 'model.originalCluster.gkeStatus.upstreamSpec.clusterAddons.networkPolicyConfig', 'model.originalCluster.gkeStatus.upstreamSpec.networkPolicyEnabled', function () {
      const currentNetworkPolicyConfig = Ember.get(this, 'config.clusterAddons.networkPolicyConfig') ?? false;
      const ogNetworkPolicyConfig = Ember.get(this, 'model.originalCluster.gkeStatus.upstreamSpec.clusterAddons.networkPolicyConfig') ?? false;
      const ogNetworkPolicyEnabled = Ember.get(this, 'model.originalCluster.gkeStatus.upstreamSpec.networkPolicyEnabled') ?? false;

      if (this.isNewOrEditable) {
        return false;
      } else {
        if (this.editing) {
          if (!ogNetworkPolicyConfig && !ogNetworkPolicyEnabled) {
            return true;
          } else if (!currentNetworkPolicyConfig) {
            return true;
          }
        }
      }

      return false;
    }),
    shouldDisableNetworkPolicyConfig: Ember.computed('config.networkPolicyEnabled', 'editing', 'isNewOrEditable', 'model.originalCluster.gkeStatus.upstreamSpec.clusterAddons.networkPolicyConfig', 'model.originalCluster.gkeStatus.upstreamSpec.networkPolicyEnabled', function () {
      const currentNetworkPolicyEnabled = Ember.get(this, 'config.networkPolicyEnabled') ?? false;
      const ogNetworkPolicyEnabled = Ember.get(this, 'model.originalCluster.gkeStatus.upstreamSpec.networkPolicyEnabled') ?? false;
      const ogNetworkPolicyConfig = Ember.get(this, 'model.originalCluster.gkeStatus.upstreamSpec.clusterAddons.networkPolicyConfig') ?? false;

      if (this.isNewOrEditable) {
        return false;
      } else {
        if (this.editing) {
          if (currentNetworkPolicyEnabled && !ogNetworkPolicyEnabled) {
            return true;
          } else if (ogNetworkPolicyEnabled && ogNetworkPolicyConfig) {
            return true;
          }
        }
      }

      return false;
    }),
    hasProvisioned: Ember.computed('model.cluster', function () {
      const cluster = Ember.get(this, 'model.cluster');
      const {
        state = '',
        isError = false
      } = cluster;
      let clusterHasProvisioned = true;

      if (isError && state === 'provisioning') {
        var _cluster$gkeStatus;

        if (Ember.isEmpty(cluster === null || cluster === void 0 ? void 0 : (_cluster$gkeStatus = cluster.gkeStatus) === null || _cluster$gkeStatus === void 0 ? void 0 : _cluster$gkeStatus.upstreamSpec)) {
          clusterHasProvisioned = false;
        }
      }

      return clusterHasProvisioned;
    }),
    isNewOrEditable: Ember.computed('hasProvisioned', 'isNew', 'mode', function () {
      const isNew = Ember.get(this, 'isNew');

      if (isNew) {
        return true;
      }

      return this.mode === 'edit' && !this.hasProvisioned;
    }),
    importedClusterIsPending: Ember.computed('clusterIsPending', 'model.originalCluster', function () {
      const {
        clusterIsPending
      } = this;
      const originalCluster = Ember.get(this, 'model.originalCluster');
      const ourClusterSpec = Ember.get(originalCluster ?? {}, 'gkeConfig');
      const upstreamSpec = Ember.get(originalCluster ?? {}, 'gkeStatus.upstreamSpec');
      return clusterIsPending && Ember.get(ourClusterSpec, 'imported') && !Ember.isEmpty(upstreamSpec);
    }),
    clusterIsPending: Ember.computed('clusterState', function () {
      const {
        clusterState
      } = this;
      return ['pending', 'provisioning', 'waiting'].includes(clusterState);
    }),
    cloudCredentials: Ember.computed('globalStore', 'model.cloudCredentials', 'originalSecret', function () {
      const {
        model: {
          cloudCredentials
        }
      } = this;
      const out = cloudCredentials.filter(cc => Object.prototype.hasOwnProperty.call(cc, 'googlecredentialConfig'));

      if (this.originalSecret && !out.find(x => x.id === this.originalSecret)) {
        const obj = this.globalStore.createRecord({
          name: `${this.originalSecret.replace(/^cattle-global-data:/, '')} (current)`,
          id: this.originalSecret,
          type: 'cloudCredential',
          googlecredentialConfig: {}
        });
        out.push(obj);
      }

      return out;
    }),
    disableSecondaryRangeNames: Ember.computed('config.ipAllocationPolicy.{createSubnetwork,useIpAliases}', function () {
      const ipAllocationPolicy = Ember.get(this, 'config.ipAllocationPolicy');
      const {
        createSubnetwork = false,
        useIpAliases = false
      } = ipAllocationPolicy ?? {
        createSubnetwork: false,
        useIpAliases: false
      };

      if (!useIpAliases && !createSubnetwork) {
        return true;
      }

      if (useIpAliases && !createSubnetwork) {
        return false;
      }

      return true;
    }),
    locationContent: Ember.computed('config.{region,zone}', 'zoneChoices', 'regionChoices', function () {
      const {
        region,
        zone
      } = this.config;
      const {
        locationType,
        zoneChoices
      } = this;
      let locationName = null;

      if (locationType === 'zonal') {
        const arr = zone.split('-');
        locationName = `${arr[0]}-${arr[1]}`;
      } else {
        locationName = region;
      }

      return zoneChoices.filter(z => (z.name || '').startsWith(locationName) && z.name !== zone);
    }),
    maintenanceWindowChoice: Ember.computed('maintenanceWindowTimes.[]', 'config.maintenanceWindow', function () {
      return Ember.get(this, 'maintenanceWindowTimes').findBy('value', Ember.get(this, 'config.maintenanceWindow')) || {
        label: 'Any Time'
      };
    }),
    networkContent: Ember.computed('config.zone', 'networks.[]', 'sharedSubnets.[]', 'subNetworks.[]', function () {
      const subnets = Ember.get(this, 'subNetworks');
      const networks = (Ember.get(this, 'networks') || []).map(net => {
        const matchedSubnets = (subnets || []).filterBy('network', net.selfLink);
        return { ...net,
          group: 'VPC',
          shared: false,
          label: matchedSubnets.length > 0 ? `${net.name} (subnets available)` : net.name
        };
      });
      const sharedSubnets = Ember.get(this, 'sharedSubnets') || [];

      const dedupeSharedSubnets = () => {
        // Because the shared network comes in as an array of shared subnetworks,
        // the network names of the shared networks need to be deduplicated.
        let alreadyUsedNetworkNames = {};
        let dedupedSharedSubnets = [];

        for (let i = 0; i < sharedSubnets.length; i++) {
          const ssn = sharedSubnets[i];
          const networkNamePath = ssn === null || ssn === void 0 ? void 0 : ssn.network.split('/');
          const networkLabel = networkNamePath[networkNamePath.length - 1];

          if (alreadyUsedNetworkNames[networkLabel]) {
            // Avoid adding two entries for the same shared network name
            continue;
          }

          alreadyUsedNetworkNames[networkLabel] = ssn;
          dedupedSharedSubnets.push({ ...ssn,
            group: 'Shared VPC',
            shared: true,
            name: ssn === null || ssn === void 0 ? void 0 : ssn.network,
            label: networkLabel
          });
        }

        return dedupedSharedSubnets;
      };

      const dedupedSharedSubnets = dedupeSharedSubnets(sharedSubnets);
      const merged = [...networks, ...dedupedSharedSubnets];
      return merged;
    }),
    secondaryIpRangeContent: Ember.computed('subNetworkContent.[]', 'config.{network,subnetwork}', function () {
      const {
        subNetworkContent = [],
        config: {
          subnetwork
        }
      } = this;
      const subnetworkMatch = subNetworkContent.findBy('value', subnetwork);

      if (subnetworkMatch) {
        const {
          secondaryIpRanges = []
        } = subnetworkMatch;
        return secondaryIpRanges.map(s => {
          return {
            label: `${s.rangeName}(${s.ipCidrRange})`,
            value: s.rangeName,
            ipCidrRange: s.ipCidrRange
          };
        });
      }

      return [];
    }),
    subNetworkContent: Ember.computed('subNetworks.[]', 'sharedSubnets.[]', 'config.network', 'config.zone', 'config.ipAllocationPolicy.useIpAliases', function () {
      const {
        config: {
          network: networkName,
          ipAllocationPolicy: {
            useIpAliases = false
          }
        },
        networkContent,
        subNetworks = [],
        sharedSubnets = []
      } = this;
      const networkMatch = networkContent.findBy('name', networkName);
      let filteredSubnets = [];
      let mappedSubnets = [];
      let out = [];

      if (!Ember.isEmpty(networkMatch) && networkMatch.shared) {
        const sharedVpcs = sharedSubnets.filterBy('network', networkName);
        mappedSubnets = sharedVpcs.map(sVpc => {
          const networkDisplayName = sVpc.network;
          const subnetworkPath = sVpc.subnetwork.split('/');
          const subnetworkLabel = subnetworkPath[subnetworkPath.length - 1];
          return {
            label: `${subnetworkLabel} (${sVpc.ipCidrRange})`,
            value: sVpc.subnetwork,
            secondaryIpRanges: sVpc.secondaryIpRanges,
            networkDisplayName
          };
        });
        out = [...mappedSubnets];
      } else {
        filteredSubnets = (subNetworks || []).filter(s => {
          const network = networkContent.findBy('selfLink', s.network);
          const networkDisplayName = network.name;

          if (networkDisplayName === networkName) {
            return true;
          }
        });
        mappedSubnets = filteredSubnets.map(o => {
          const network = networkContent.findBy('selfLink', o.network);
          const networkDisplayName = network.name;
          return {
            label: `${o.name}(${o.ipCidrRange})`,
            value: o.name,
            secondaryIpRanges: o.secondaryIpRanges,
            networkDisplayName
          };
        });

        if (useIpAliases) {
          const defaultSubnetAry = [{
            label: this.intl.t('clusterNew.googlegke.ipPolicyCreateSubnetwork.autoLabel'),
            value: ''
          }];
          out = [...defaultSubnetAry, ...mappedSubnets];
        } else {
          out = [...mappedSubnets];
        }
      }

      return out;
    }),
    selectedCloudCredential: Ember.computed('cloudCredentials.@each.id', 'config.googleCredentialSecret', function () {
      const cur = this.config.googleCredentialSecret;
      const cloudCredentials = this.cloudCredentials;

      if (Ember.isEmpty(cloudCredentials) && Ember.isEmpty(cur)) {
        return null;
      } else {
        return cloudCredentials.findBy('id', cur.includes('cattle-global-data:') ? cur : `cattle-global-data:${cur}`);
      }
    }),
    versionChoices: Ember.computed('clusterReleaseChannel', 'editing', 'versions.{validMasterVersions,channels}', function () {
      const {
        versions,
        config,
        mode
      } = this;
      let validMasterVersions = (versions === null || versions === void 0 ? void 0 : versions.validMasterVersions) || [];
      let {
        kubernetesVersion: initialVersion
      } = config;

      if (!Ember.isEmpty(this.clusterReleaseChannel)) {
        const matchedChannel = ((versions === null || versions === void 0 ? void 0 : versions.channels) || []).findBy('channel', this.clusterReleaseChannel);

        if (matchedChannel !== null && matchedChannel !== void 0 && matchedChannel.validVersions) {
          validMasterVersions = matchedChannel === null || matchedChannel === void 0 ? void 0 : matchedChannel.validVersions;
        }
      }

      if (Ember.isEmpty(initialVersion)) {
        initialVersion = validMasterVersions[0];
      }

      if (this.editing && !validMasterVersions.includes(initialVersion)) {
        validMasterVersions.unshift(initialVersion);
      }

      _semver.default.rsort(validMasterVersions, {
        includePrerelease: true
      });

      const versionChoices = this.serviceVersions.parseCloudProviderVersionChoicesV2(validMasterVersions.slice(), initialVersion, mode, null, false, MINIMUM_VERSION);

      if (this.editing) {
        try {
          const initialSem = _semver.default.parse(initialVersion, {
            includePrerelease: true
          });

          const initalMinorVersion = initialSem.minor;
          versionChoices.forEach(vc => {
            const choiceSemver = _semver.default.parse(vc.value, {
              includePrerelease: true
            });

            const vcMinorV = choiceSemver === null || choiceSemver === void 0 ? void 0 : choiceSemver.minor;
            const diff = vcMinorV - initalMinorVersion;

            if (diff > 1) {
              Ember.setProperties(vc, {
                disabled: true,
                label: `${vc.label} ${this.intl.t('formVersions.minorWarning')}`
              });
            }
          });
        } catch (_error) {}
      }

      return versionChoices;
    }),
    zoneChoices: Ember.computed('zones.[]', function () {
      let out = (Ember.get(this, 'zones') || []).slice();
      out.forEach(obj => {
        Ember.set(obj, 'sortName', (0, _util.sortableNumericSuffix)(obj.name));
        Ember.set(obj, 'displayName', `${obj.name} (${obj.description})`);
        Ember.set(obj, 'disabled', obj.status.toLowerCase() !== 'up');
      });
      return out.sortBy('sortName');
    }),

    defaultConfig() {
      const neu = JSON.parse(JSON.stringify(this.defaultGkeConfig));
      const defNpConfig = JSON.parse(JSON.stringify(this.defalutNodePoolConfig));
      const neuNp = this.globalStore.createRecord(defNpConfig);
      Ember.set(neuNp, 'isNew', true); // for node pool row component

      Ember.set(neu, 'nodePools', [neuNp]);
      return neu;
    },

    validatePrivateConfig() {
      const config = Ember.get(this, 'config') || {};
      const {
        privateClusterConfig
      } = config;

      if (Ember.isEmpty(privateClusterConfig)) {
        return true;
      }

      if (privateClusterConfig !== null && privateClusterConfig !== void 0 && privateClusterConfig.enablePrivateNodes && Ember.isEmpty(privateClusterConfig === null || privateClusterConfig === void 0 ? void 0 : privateClusterConfig.masterIpv4CidrBlock)) {
        this.send('errorHandler', this.intl.t('clusterNew.googlegke.masterIpv4CidrBlock.error'));
        return false;
      }

      return true;
    },

    willSave() {
      this.validateNodePools();
      this.validatePrivateConfig();

      if (!Ember.isEmpty(this.errors)) {
        return false;
      }

      const config = Ember.get(this, 'config') || {};

      if (Ember.get(this, 'config.ipAllocationPolicy.useIpAliases')) {
        Ember.set(config, 'ipAllocationPolicy.clusterIpv4Cidr', '');
      } else {
        if (Ember.isEmpty(Ember.get(config, 'subnetwork'))) {
          this.send('errorHandler', this.intl.t('clusterNew.googlegke.useIpAliases.error'));
          return false;
        }
      }

      if (!Ember.get(config, 'masterAuthorizedNetworks.enabled')) {
        delete config.masterAuthorizedNetworks.cidrBlocks;
      }

      const locationType = Ember.get(this, 'locationType');

      if (locationType === this.google.defaultZoneType) {
        Ember.set(config, 'region', null);
      } else {
        Ember.set(config, 'zone', null);
      }

      const locationContent = Ember.get(this, 'locationContent');
      const locations = locationContent.filter(l => l.checked).map(l => l.name);

      if (locations.length > 0) {
        if (this.locationType === 'zonal') {
          locations.push(Ember.get(config, 'zone'));
        }

        Ember.set(config, 'locations', locations);
      } else {
        Ember.set(config, 'locations', []);
      }

      return this._super(...arguments);
    },

    validateNodePools() {
      const nodePools = Ember.get(this, 'primaryResource.gkeConfig.nodePools');
      const errors = [];

      if (!Ember.isEmpty(nodePools)) {
        const nodePoolErrors = [];
        nodePools.forEach(np => {
          const npErr = np.validationErrors();
          nodePoolErrors.push(npErr);
        });

        if (!Ember.isEmpty(nodePoolErrors)) {
          errors.pushObjects(nodePoolErrors.flat());
        }
      }

      Ember.set(this, 'errors', errors);
      return errors.length >= 1 ? true : null;
    },

    syncUpstreamConfig() {
      const originalCluster = Ember.get(this, 'model.originalCluster').clone();
      const ourClusterSpec = Ember.get(originalCluster, 'gkeConfig');
      const upstreamSpec = Ember.get(originalCluster, 'gkeStatus.upstreamSpec');

      if (!Ember.isEmpty(upstreamSpec)) {
        Object.keys(upstreamSpec).forEach(k => {
          if (Ember.isEmpty(Ember.get(ourClusterSpec, k)) && !Ember.isEmpty(Ember.get(upstreamSpec, k))) {
            Ember.set(this, `config.${k}`, Ember.get(upstreamSpec, k));
          }
        });
      }
    }

  });

  _exports.default = _default;
});